import { Suspense } from 'react';

import { ErrorBoundary, Typography } from '@packages/shared';

import { CategoryPageBackChannelRequestDebugInfo } from './CategoryPageBackChannelRequestDebugInfo';
import { SearchResultPageBackChannelRequestDebugInfo } from './SearchResultPageBackChannelRequestDebugInfo';

export const BackChannelRequestDebugInfo = ({
  variables,
}: {
  variables: { query: string } | { pathname: string };
}) => (
  <ErrorBoundary fallback={<Typography variant="h1">Error, please try again</Typography>}>
    <Suspense fallback={<Typography variant="h1">Loading...</Typography>}>
      {'pathname' in variables && <CategoryPageBackChannelRequestDebugInfo variables={variables} />}
      {'query' in variables && (
        <SearchResultPageBackChannelRequestDebugInfo variables={variables} />
      )}
    </Suspense>
  </ErrorBoundary>
);
