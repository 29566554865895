'use client';

import { useState } from 'react';

import { Button } from '@packages/shared/src/components/Button/Button';
import { DebugPanel } from '@packages/shared/src/components/DebugPanel/DebugPanel';
import { Stack } from '@packages/shared/src/components/Stack/Stack';
import { Tab, Tabs } from '@packages/shared/src/components/Tabs/Tabs';
import { ToggleButtonGroup } from '@packages/shared/src/components/ToggleButtonGroup/ToggleButtonGroup';
import { Typography } from '@packages/shared/src/components/Typography/Typography';

import { type SearchParametersInput } from '@packages/gql/generated/shopping/graphql';
import type { TypedDocumentNode } from 'urql';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchDebugPanelFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchDebugPanelFragmentFragmentDoc';
import { useCookies } from '@packages/shared/src/providers/CookieProvider/CookieProvider';

import { useAtom, useAtomValue } from 'jotai';
import { CopyableCodeBlock } from './codeBlocks/CopyableCodeBlock';
import { BackChannelRequestDebugInfo } from './backChannelRequest/BackChannelRequestDebugInfo';
import * as features from '../../activeFeatureFlags';
import { hasFeatureFlag } from '../../utils/featureFlags/hasFeatureFlag';
import { getFeatureGroup } from '../../utils/featureFlags/getFeatureGroup';
import type { FeatureFlag, FeatureFlagWithComplexTest } from '../../utils/featureFlags/types';
import { numberOfSeenProductsAtom, showSeenProductsAtom } from '../../atoms/numberOfSeenProducts';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tabs = ['basics', 'gql', 'eps'] as const;

/* GraphQL */ `
  fragment SearchDebugPanelFragment on ProperCategoryQueryResult {
    currentCategoryId
  }
`;

const FeatureToggle = ({ feature }: { feature: FeatureFlag }) => {
  const { getCookies, setCookie, destroyCookie } = useCookies();

  const getValueFromCookies = () => {
    const state = hasFeatureFlag(feature, getCookies());
    return state ? 'override' : 'default';
  };

  const [value, setValue] = useState(getValueFromCookies());

  const handleChange = (_: unknown, newValue: string) => {
    if (newValue === 'override') {
      setCookie(null, `feature/${feature.name}`, '1', {
        path: '/',
      });
    } else {
      destroyCookie(null, `feature/${feature.name}`, {
        path: '/',
      });
    }

    setValue(getValueFromCookies());
  };

  const options = [
    { value: 'default', label: 'Disabled' },
    {
      value: 'override',
      label: 'Enabled',
    },
  ];

  return (
    <ToggleButtonGroup
      id={`feature-toggle-${feature.name}`}
      label={feature.name}
      value={value}
      options={options}
      handleChange={handleChange}
    />
  );
};

const FeatureGroupToggle = ({ feature }: { feature: FeatureFlagWithComplexTest<unknown> }) => {
  const { getCookies, setCookie, destroyCookie } = useCookies();

  const getValueFromCookies = () => {
    const state = getFeatureGroup(feature, getCookies());
    return state === feature.test.featureCookieGroup ? 'override' : 'default';
  };

  const [value, setValue] = useState(getValueFromCookies());

  const handleChange = (_: unknown, newValue: string) => {
    if (newValue === 'override') {
      setCookie(null, `feature/${feature.name}`, '1', {
        path: '/',
      });
    } else {
      destroyCookie(null, `feature/${feature.name}`, {
        path: '/',
      });
    }

    setValue(getValueFromCookies());
  };

  const options = [
    { value: 'default', label: String(feature.test.defaultGroup) },
    {
      value: 'override',
      label: String(feature.test.featureCookieGroup),
    },
  ];

  return (
    <ToggleButtonGroup
      id={`feature-toggle-${feature.name}`}
      label={feature.name}
      value={value}
      options={options}
      handleChange={handleChange}
    />
  );
};

/**
 * Debug panel to assist backend developers and product staff with debugging and analyzing search requests.
 * */
export const SearchDebugPanel = <
  Query,
  Variables extends
    | { params: SearchParametersInput; query: string }
    | { params: SearchParametersInput; pathname: string },
>({
  maskedCategoryResult,
  document,
  data,
  variables,
}: {
  maskedCategoryResult?: FragmentType<typeof SearchDebugPanelFragmentFragmentDoc>;
  document?: TypedDocumentNode<Query, any>; // typing this to Variables is pretty complex, so for now this will have to do
  data?: Query;
  variables?: Variables;
}) => {
  const [activeTab, setActiveTab] = useState<(typeof tabs)[number]>('basics');
  const numberOfSeenProducts = useAtomValue(numberOfSeenProductsAtom);
  const [showSeenProducts, setShowSeenProducts] = useAtom(showSeenProductsAtom);
  const toggleShowSeenProducts = () => {
    setShowSeenProducts((prev) => !prev);
  };

  const categoryResult = unmask<typeof SearchDebugPanelFragmentFragmentDoc>(maskedCategoryResult);

  return (
    <DebugPanel
      addons={
        showSeenProducts && (
          <>
            <Typography variant="body2">{numberOfSeenProducts} seen products.</Typography>
            <style>
              {`
.product-card.seen {
  position: relative;
}
.product-card.seen::after {
  content: '';
  position: absolute;
  inset: -5px;
  background-color: #00ff0033;
  border-radius: 5px;
}
`}
            </style>
          </>
        )
      }
    >
      <Stack gap={2} minHeight={0}>
        <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)} variant="fullWidth">
          <Tab value="basics" label="Basics" />
          <Tab value="gql" label="GraphQL" />
          <Tab value="eps" label="EPS" />
        </Tabs>

        <Stack
          padding={2}
          sx={{
            overflowY: 'auto',
            '& > pre': {
              overflowX: 'auto',
              border: '1px solid #ccc',
              backgroundColor: '#eee',
            },
          }}
        >
          {activeTab === 'basics' && (
            <>
              <Typography variant="h4">Context</Typography>
              {categoryResult && (
                <Typography variant="body1">
                  Current Category: {categoryResult.currentCategoryId}
                </Typography>
              )}
              {!categoryResult && (
                <>
                  <Typography variant="body1">
                    Number of seen products: {numberOfSeenProducts}
                  </Typography>

                  <div>
                    <Button onClick={toggleShowSeenProducts}>
                      {showSeenProducts ? 'Hide' : 'Show'} seen products
                    </Button>
                  </div>
                </>
              )}
              <Typography variant="h4">Available feature flags</Typography>
              {Object.values(features).map((feature) =>
                'test' in feature ? (
                  <FeatureGroupToggle key={feature.name} feature={feature} />
                ) : (
                  <FeatureToggle key={feature.name} feature={feature} />
                ),
              )}
            </>
          )}

          {activeTab === 'gql' && (
            <>
              {document?.loc && (
                <CopyableCodeBlock content={document.loc?.source.body} maxLines={10}>
                  Query
                </CopyableCodeBlock>
              )}
              {data && (
                <CopyableCodeBlock content={data} maxLines={10}>
                  Response
                </CopyableCodeBlock>
              )}
              {variables && <CopyableCodeBlock content={variables}>Variables</CopyableCodeBlock>}
            </>
          )}

          {activeTab === 'eps' && variables && (
            <BackChannelRequestDebugInfo variables={variables} />
          )}
        </Stack>
      </Stack>
    </DebugPanel>
  );
};

// Default export needed for lazy loadin g
// eslint-disable-next-line import/no-default-export
export default SearchDebugPanel;
