import { Notification } from '@packages/shared';
import type { FC } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useAtom } from 'jotai';
import { isColorSelectFailedNotificationOpenAtom } from './ColorSelectFailedNotification.atoms';

const messages = defineMessages({
  notification: {
    id: 'colorSelectFailed.notification',
    defaultMessage:
      'Beim Laden der ausgewählten Farbe ist ein Fehler aufgetreten. Bitte versuche es erneut.',
  },
});

/**
 * Notification to show when the color select request failed
 * */
export const ColorSelectFailedNotification: FC = () => {
  const [open, setOpen] = useAtom(isColorSelectFailedNotificationOpenAtom);

  return (
    <Notification
      open={open}
      severity="warning"
      autoHideDuration={7000}
      onClose={() => setOpen(false)}
    >
      <FormattedMessage key="colorSelectFailed.notification-text" {...messages.notification} />
    </Notification>
  );
};
