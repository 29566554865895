import { Box } from '@packages/shared';
import { limitLines } from './limitLines';

export const CodeBlock = ({
  content,
  maxLines,
}: {
  content: string | object;
  maxLines?: number;
}) => (
  <Box
    component="pre"
    sx={{
      border: '1px solid #ccc',
      backgroundColor: '#eee',
      overflowX: 'hidden',
      flex: '1 0 auto',
    }}
  >
    <code>{limitLines(content, maxLines)}</code>
  </Box>
);
