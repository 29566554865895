import { useEffect } from 'react';
import { useUrl } from '../useUrl/useUrl';

export const rememberPageData = (sku: string, path: string) => {
  window.sessionStorage.setItem('rememberedPageData', JSON.stringify({ sku, path }));
};

export const useScrollRestoration = () => {
  const asPath = useUrl(true);

  useEffect(() => {
    const pageData = window.sessionStorage.getItem('rememberedPageData');
    if (pageData) {
      const rememberedData = JSON.parse(pageData);
      if (rememberedData.path === asPath) {
        const element = document.getElementById(rememberedData.sku);
        if (element) {
          requestAnimationFrame(() => element.scrollIntoView({ block: 'center' }));
        }
      }
      window.sessionStorage.removeItem('rememberedPageData');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);
};
