import type { FC } from 'react';
import { Notification } from '@packages/shared';
import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
  notification: {
    id: 'eraseFilters.notification',
    defaultMessage:
      'Die ausgewählten Filter der vorherigen Seite wurden entfernt, um Dir Artikel anbieten zu können. Nutze bei Bedarf erneut die Filterauswahl.',
  },
});

export type ErasedFiltersNotificationProps = {
  /** Whether filters were removed by the server to show results */
  show?: boolean;
  /** Will be called when notification is shown */
  onShow: () => void;
};

/**
 * Handles the notification when filters are erased.
 * When the user filters an empty search result, the filters are erased and the user will be notified.
 * */
export const ErasedFiltersNotification: FC<ErasedFiltersNotificationProps> = ({ show, onShow }) => {
  const [showFilterErasedNotification, setShowFilterErasedNotification] = useState(false);

  useEffect(() => {
    if (show) {
      setShowFilterErasedNotification(true);
      onShow();
    }
  }, [show, onShow]);

  return (
    <Notification
      open={showFilterErasedNotification}
      severity="warning"
      autoHideDuration={7000}
      onClose={() => setShowFilterErasedNotification(false)}
    >
      <FormattedMessage key="eraseFilters.notification-text" {...messages.notification} />
    </Notification>
  );
};
