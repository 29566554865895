/* @jsxImportSource react */
import { cn } from '../../utils/cn/cn';

export interface DynamicYieldProps {
  dataDyId?: string;
  className?: string;
}

/**
 * This component is used to render DynamicYield content. If the content is empty, it will be hidden, so the styling wont apply.
 * Otherwise you might have margins or paddings for an empty element.
 */
const DynamicYield = ({ dataDyId = 'empty', className }: DynamicYieldProps) => (
  <div className={cn('empty:hidden', className)} data-dy={dataDyId} data-testid="dy" />
);

DynamicYield.displayName = 'DynamicYield';

export { DynamicYield };
