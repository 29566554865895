export const limitLines = (content: string | object, maxLines?: number) => {
  const stringContent = typeof content === 'string' ? content : JSON.stringify(content, null, 2);

  if (!maxLines) return stringContent;

  const lines = stringContent.split('\n');

  return lines
    .slice(0, maxLines)
    .concat(lines.length > maxLines ? [`[... ${lines.length - maxLines} more lines hidden]`] : [])
    .join('\n');
};
