import { useQuery } from 'urql';
import { CategoryPageBackChannelRequestDebugDataDocument } from '@packages/gql/generated/shopping/CategoryPageBackChannelRequestDebugDataDocument';

import { BackChannelRequestCodeBlocks } from './BackChannelRequestCodeBlocks';

/* GraphQL */ `
  query CategoryPageBackChannelRequestDebugData(
    $params: SearchParametersInput!
    $pathname: String!
  ) {
    category(params: $params, pathname: $pathname) {
      ...BackChannelRequestCodeBlocksFragment
    }
  }
`;

export const CategoryPageBackChannelRequestDebugInfo = <Variables extends Record<string, unknown>>({
  variables,
}: {
  variables: Variables;
}) => {
  const [{ data }] = useQuery({
    query: CategoryPageBackChannelRequestDebugDataDocument,
    variables: variables as any, // typing everything generically is a pain in the ass, this is just a debug panel
    requestPolicy: 'network-only', // for unknown reasons related to the graphcache, the query does not perform a network request unless the fragment contents are inlined into the query as well, or the requestPolicy is set to network-only
  });

  if (!data) return null;

  return <BackChannelRequestCodeBlocks maskedResult={data.category} />;
};
