export const getCardCounts = (
  effectiveBreakpoint: 'xs' | 'sm' | 'md' | 'lg',
  columns: { xs: number; sm: number; md: number; lg: number },
  hasSponsoredProducts?: boolean,
) => {
  const productsPerRow = columns[effectiveBreakpoint];

  // always pre-render 2 full rows of products, including sponsoredProducts if available
  const sponsoredProductCount = hasSponsoredProducts ? productsPerRow : 0;
  const prerenderedProductCount = 2 * productsPerRow - sponsoredProductCount;

  return {
    sponsoredProductCount,
    prerenderedProductCount,
  };
};
