import type { ReactNode } from 'react';
import { CodeBlock } from './CodeBlock';
import { CopyHeader } from './CopyHeader';

export const CopyableCodeBlock = ({
  children,
  content,
  maxLines,
}: {
  children: ReactNode;
  content: string | object;
  maxLines?: number;
}) => (
  <>
    <CopyHeader content={content}>{children}</CopyHeader>
    <CodeBlock content={content} maxLines={maxLines} />
  </>
);
