'use client';

import { type FC } from 'react';

import type { FragmentType } from '@packages/gql/generated/shopping';
import type { UseAbTestingCookiesFromEpsFragmentFragmentDoc } from '@packages/gql/generated/shopping/UseAbTestingCookiesFromEpsFragmentFragmentDoc';
import { useAbTestingCookiesFromEps } from '@packages/search/src/hooks/useAbTestingCookiesFromEps';
import { usePersonalizationCookie } from '@packages/search/src/hooks/usePersonalizationCookie';
import { useScrollRestoration } from '@packages/shared/src/hooks/useScrollRestoration/useScrollRestoration';

export type SharedPageEffectsProps = {
  maskedData?: FragmentType<typeof UseAbTestingCookiesFromEpsFragmentFragmentDoc>;
};

export const SharedPageEffects: FC<SharedPageEffectsProps> = ({ maskedData }) => {
  usePersonalizationCookie();
  useAbTestingCookiesFromEps(maskedData);
  useScrollRestoration();

  return null;
};
