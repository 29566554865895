import { SvgIcon, SvgIconProps } from "@mui/material";
import { memo } from "react";
const SvgCopy32 = (props: SvgIconProps) => {
  const {
    sx,
    ...other
  } = props;
  return <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" sx={{
    ...sx
  }} {...other}><path d="M11.018 8.5v1H10a1.5 1.5 0 0 0-1.493 1.356L8.5 11v13a1.5 1.5 0 0 0 1.356 1.493L10 25.5h9a1.5 1.5 0 0 0 1.493-1.356L20.5 24v-1.95h1V24a2.5 2.5 0 0 1-2.336 2.495L19 26.5h-9a2.5 2.5 0 0 1-2.495-2.336L7.5 24V11a2.5 2.5 0 0 1 2.336-2.495L10 8.5zm6.963-4a.49.49 0 0 1 .373.146l6 6c.1.1.146.224.147.345L24.5 11v8.118c0 1.313-1.048 2.382-2.346 2.382h-8.308c-1.298 0-2.346-1.069-2.346-2.382V6.882c0-1.313 1.048-2.382 2.346-2.382Zm-.481 1h-3.654c-.741 0-1.346.617-1.346 1.382v12.236c0 .765.605 1.382 1.346 1.382h8.308c.741 0 1.346-.617 1.346-1.382V11.5h-3.476a2.52 2.52 0 0 1-2.519-2.349l-.005-.165zm1 .707v2.779c0 .789.608 1.438 1.385 1.508l.139.006h2.769z" className="copy-32_svg__path-1" /></SvgIcon>;
};
const Memo = memo(SvgCopy32);
export default Memo;