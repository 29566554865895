import type { FC, ReactNode } from 'react';
import { Suspense, useEffect } from 'react';

import { ErrorBoundary } from '@packages/shared/src/components/ErrorBoundary/ErrorBoundary';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { useUrl } from '@packages/shared/src/hooks/useUrl/useUrl';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { ProductListPageLayoutFragmentFragmentDoc } from '@packages/gql/generated/shopping/ProductListPageLayoutFragmentFragmentDoc';
import { useAbTesting } from '@packages/shared/src/abtesting/useAbTesting/useAbTesting';
import { DynamicYield } from '@packages/shared/src/ui/DynamicYield/DynamicYield';

import { usePaging } from '../../hooks/usePaging';
import { InfiniteProductGrid } from '../InfiniteProductGrid/InfiniteProductGrid';
import { ProductGrid } from '../ProductGrid/ProductGrid';
import { ProductListPagination } from '../ProductListPagination/ProductListPagination';
import type { FilterSettingsProps } from '../FilterSettings/FilterSettings';
import { FilterSettings } from '../FilterSettings/FilterSettings';
import { SearchAdServerIntegration } from '../SearchAdServerIntegration/SearchAdServerIntegration';
import { DisplaySettingsDesktop } from '../DisplaySettingsDesktop/DisplaySettingsDesktop';
import { DisplaySettingsMobile } from '../DisplaySettingsMobile/DisplaySettingsMobile';
import { SearchStructuredData } from '../SearchStructuredData/SearchStructuredData';
import { getTestId } from '../../utils/featureFlags/getTestId';
import { newInfiniteScrolling } from '../../activeFeatureFlags';
import { useSeenProductsTracking } from './useSeenProductsTracking';
import { getPageLeaveKey } from './getPageLeaveKey';

/* GraphQL */ `
  fragment ProductListPageLayoutFragment on SearchProductResult {
    ...FilterSettingsFragment
    ...UsePagingFragment
    ...DisplaySettingsDesktopFragment
    ...DisplaySettingsMobileFragment
    ...ProductListPaginationFragment
    ...ProductGridFragment
    ...InfiniteProductGridFragment
    ...SearchStructuredDataFragment
    summary {
      dominantProductGroup
    }

    ... on SuccessfulSearchResult {
      recoveryStrategy {
        ... on FilterAdjustment {
          removeFilters
        }
      }
    }
  }
`;

export type ProductListPageLayoutProps = {
  maskedData: FragmentType<typeof ProductListPageLayoutFragmentFragmentDoc>;
  topLevelCategories?: string[];
  aboveMainContentComponent: ReactNode;
  leftSidebarDesktopComponent: ReactNode;
  renderCategoryDialogContent: FilterSettingsProps['renderCategoryDialogContent'];
  belowProductsComponent: ReactNode;
  referrer?: string;
};

/**
 * Shared layout for pages displaying product lists
 *
 * Includes the actual product grid, filtering, sorting, display mode, pagination, and slots for additional content.
 */
export const ProductListPageLayout: FC<ProductListPageLayoutProps> = ({
  maskedData,
  topLevelCategories,
  aboveMainContentComponent,
  leftSidebarDesktopComponent,
  renderCategoryDialogContent,
  belowProductsComponent,
  referrer,
}) => {
  const data = unmask<typeof ProductListPageLayoutFragmentFragmentDoc>(maskedData);

  // SEARCH-3043 instrumentation for AB test
  const { setOutcome } = useAbTesting();
  useEffect(() => {
    setOutcome(getTestId(newInfiniteScrolling), {
      RPL: 1,
    });
  }, [setOutcome]);

  const { isMobile, isDesktop } = useDeviceType();
  const url = useUrl();

  const pageLeaveKey = getPageLeaveKey(url);
  const { incrementNumberOfSeenProducts } = useSeenProductsTracking(pageLeaveKey);

  const { pagingMode, pageCount, currentPage } = usePaging(data);

  const {
    summary: { dominantProductGroup },
  } = data;

  const removeFilters =
    data.__typename === 'SuccessfulSearchResult' &&
    data.recoveryStrategy?.__typename === 'FilterAdjustment' &&
    data.recoveryStrategy.removeFilters;

  return (
    // position relative is necessary for the SearchAdServerIntegration component
    <div className="relative flex flex-col gap-1 p-1">
      <SearchStructuredData maskedData={data} />

      {aboveMainContentComponent}

      <FilterSettings
        maskedData={data}
        showRemovedFiltersNotification={removeFilters}
        renderCategoryDialogContent={renderCategoryDialogContent}
      />

      {isDesktop ? (
        <DisplaySettingsDesktop maskedData={data} />
      ) : (
        <DisplaySettingsMobile maskedData={data} />
      )}

      <hr className="opacity-15" />

      <div className="flex gap-1">
        {!isMobile && <div className="md:w-[25%] lg:w-[20%]">{leftSidebarDesktopComponent}</div>}

        <div className="flex flex-1 flex-col">
          {/* NOTE: placeholder for dynamic yield category slider content, right above the product list */}
          <DynamicYield dataDyId="dy_productlist_categoryslider" />

          {/* NOTE: minWidth is required to properly propagate size constraints to RecoSlider and prevent the page from blowing up */}
          {/* see also: https://github.com/philipwalton/flexbugs#1-minimum-content-sizing-of-flex-items-not-honored */}
          <div className="flex min-w-0 flex-col gap-2">
            {pagingMode === 'infinite' ? (
              <InfiniteProductGrid
                maskedResult={data}
                key={url}
                referrer={referrer}
                topLevelCategories={topLevelCategories}
                onProductFirstBecameVisible={incrementNumberOfSeenProducts}
              />
            ) : (
              <ProductGrid
                maskedResult={data}
                key={url}
                pageNumber={currentPage}
                topLevelCategories={topLevelCategories}
                onProductFirstBecameVisible={incrementNumberOfSeenProducts}
              />
            )}
            {pageCount > 1 && pagingMode === 'pagination' && (
              <div className="self-center">
                <ProductListPagination maskedData={data} />
              </div>
            )}
            {belowProductsComponent}
          </div>
        </div>
      </div>

      <ErrorBoundary>
        <Suspense>
          <SearchAdServerIntegration majorMkz={dominantProductGroup ?? undefined} />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
