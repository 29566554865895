import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'urql';

import { Box } from '@packages/shared';
import { useIntersectionObserver } from '@packages/shared/src/hooks/useIntersectionObserver/useIntersectionObserver';
import { usePushQueryChange } from '@packages/shared/src/hooks/usePushQueryChange/usePushQueryChange';
import { useUrl } from '@packages/shared/src/hooks/useUrl/useUrl';
import { mergeQueryParameters } from '@packages/shared/src/utils/mergeQueryParameters/mergeQueryParameters';
import { searchParamsToObject } from '@packages/shared/src/utils/searchParamsToObject/searchParamsToObject';
import { ProductCard } from '@packages/modules/src/ProductCard/ProductCard';
import { useIsBot } from '@packages/tracking/src/hooks/useIsBot/useIsBot';
import type { ProductCardSpecificProps } from '@packages/modules/src/ProductCard/types';
import { rememberPageData } from '@packages/shared/src/hooks/useScrollRestoration/useScrollRestoration';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SearchProductCardFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardFragmentFragmentDoc';
import type { SearchProductCardResultFragmentFragmentDoc } from '@packages/gql/generated/shopping/SearchProductCardResultFragmentFragmentDoc';
import { SearchProductCardVariationDataDocument } from '@packages/gql/generated/shopping/SearchProductCardVariationDataDocument';
import { useAbTesting } from '@packages/shared/src/abtesting/useAbTesting/useAbTesting';

import { useAtom, useAtomValue } from 'jotai';
import { useSearchParams } from 'next/navigation';
import { INSPIRING_SEARCH_V2_TEST_ID } from '@packages/modules/src/Header/useInspiringSearchSettings.shared';
import { getHeightHeuristic } from './heightHeuristic';
import { useProductImpressionTracking } from './tracking/useProductImpressionTracking';
import { useProductClickTracking } from './tracking/useProductClickTracking';
import { buildGlycerinTrackingData } from './tracking/buildGlycerinTrackingData';
import { useVariationDataForProductCard } from './useVariationDataForProductCard';
import { useDisplayMode } from '../../hooks/useDisplayMode';
import { isColorSelectFailedNotificationOpenAtom } from '../ColorSelectFailedNotification/ColorSelectFailedNotification.atoms';
import { decodeInspiredResult, encodePage } from '../../queryEncoding';
import { INSTALLMENTS_PAYMENT_TEST_ID } from './useHasInstallmentsPayment';
import { getTestId } from '../../utils/featureFlags/getTestId';
import { newInfiniteScrolling } from '../../activeFeatureFlags';
import { showSeenProductsAtom } from '../../atoms/numberOfSeenProducts';

/* GraphQL */ `
  fragment SearchProductCardResultFragment on SearchProductResult {
    id
    ...SearchProductCardImpressionTrackingResultFragment
    ...SearchProductCardClickTrackingResultFragment
    ...SearchProductCardGlycerinTrackingResultFragment
    ...UseDisplayModeFragment
    abTesting {
      newTestAssignments {
        testId
      }
    }
  }
`;

/* GraphQL */ `
  query SearchProductCardVariationData($resultId: ID!, $styleId: ID!) {
    styleDetails(resultId: $resultId, styleId: $styleId) {
      variationGroups {
        ...SearchProductCardFullVariationGroupFragment
        ...SearchProductCardClickTrackingVariationGroupFragment
        ...SearchProductCardGlycerinTrackingVariationGroupFragment
      }
    }
  }
`;

export type ActualSearchProductCardProps = {
  maskedItem: FragmentType<typeof SearchProductCardFragmentFragmentDoc>;
  maskedResult: FragmentType<typeof SearchProductCardResultFragmentFragmentDoc>;
  pageNumber: number;
  onClick?: () => void;
} & Pick<
  ProductCardSpecificProps,
  'priority' | 'useAdditionalFlagsSpacer' | 'useRatingsSpacer' | 'isSponsored'
> & {
    wishListTrackingProps?: Omit<ProductCardSpecificProps['wishListTrackingProps'], 'product'>;
  } & Pick<ProductCardSpecificProps['price'], 'useUnitPriceSpacer'>;

/**
 * A search-page-specific wrapper around `ProductCard`
 */
const ActualSearchProductCard: FC<ActualSearchProductCardProps> = (props) => {
  // local state to allow changing colors, also used for fetching full product data
  const [activeVariationIndex, setActiveVariationIndex] = useState(0);
  // ref to check if data has already failed to fetch
  // because if there is an error the useQuery won't fetch data again
  // unless the query is reexecuted with { requestPolicy: 'network-only' }
  const isErrorAlreadyOccured = useRef<boolean>(false);

  const [isColorSelectFailedNotificationOpen, setIsColorSelectFailedNotificationOpen] = useAtom(
    isColorSelectFailedNotificationOpenAtom,
  );

  const searchParams = useSearchParams();
  const urlQuery = searchParamsToObject(searchParams);

  const {
    maskedItem,
    maskedResult,
    pageNumber,
    useUnitPriceSpacer,
    wishListTrackingProps,
    onClick,
    ...passthroughProps
  } = props;

  const result = unmask<typeof SearchProductCardResultFragmentFragmentDoc>(maskedResult);
  const item = unmask<typeof SearchProductCardFragmentFragmentDoc>(maskedItem);

  const [{ data, error, fetching }, executeQuery] = useQuery({
    query: SearchProductCardVariationDataDocument,
    variables: {
      resultId: result.id,
      styleId: item.styleId,
    },
    pause: activeVariationIndex === 0,
    context: useMemo(() => ({ suspense: false }), []),
  });

  // if full variation data is not yet available, fall back to the first variation until data has loaded
  const maskedActiveVariation =
    data?.styleDetails.variationGroups[activeVariationIndex] ?? item.primaryVariationGroup;

  const { dispatchProductClickTracking } = useProductClickTracking(
    result,
    item,
    maskedActiveVariation,
  );

  const { clickTrackingProps, wishListTrackingProduct } = buildGlycerinTrackingData(
    result,
    item,
    maskedActiveVariation,
  );

  const { displayMode } = useDisplayMode(result);
  const { setRelevantProduct, setOutcome } = useAbTesting();

  const activeVariationProps = useVariationDataForProductCard(
    maskedItem,
    activeVariationIndex,
    maskedActiveVariation,
    displayMode,
    pageNumber,
    useUnitPriceSpacer,
  );

  // NOTE: for some reason this does not update with `history.replaceState` correctly, but the page is overwritten below anyways
  const url = useUrl(true);
  const { pushQueryChange } = usePushQueryChange({ trailingSlash: true });

  const handleClick = () => {
    dispatchProductClickTracking();

    const queryChange = encodePage(pageNumber);
    pushQueryChange(queryChange, { shallow: true });

    const modifiedUrl = mergeQueryParameters(url, queryChange);
    rememberPageData(item.styleId, modifiedUrl);

    setRelevantProduct('2024_04_SoFiOn', item.styleId);

    if (activeVariationProps.price.minInstallment) {
      setRelevantProduct(INSTALLMENTS_PAYMENT_TEST_ID, item.styleId);
    }

    const isInspiredResult = decodeInspiredResult(urlQuery);

    if (isInspiredResult) {
      setRelevantProduct(INSPIRING_SEARCH_V2_TEST_ID, item.styleId);
    }

    // later steps in the customer flow need to be able to correlate the product with the test (e.g. in detail view or checkout)
    for (const testAssignment of result.abTesting?.newTestAssignments ?? []) {
      setRelevantProduct(testAssignment.testId, item.styleId);
    }

    // SEARCH-3043 instrumentation for AB test
    setOutcome(getTestId(newInfiniteScrolling), {
      COF2: pageNumber,
    });
    onClick?.();
  };

  // if failed to fetch full variation data,
  // fall back to the first variation and show a error notification
  useEffect(() => {
    // if there is no error, the active variation is already 0,
    // or query is still fetching, do early return
    if (!error || activeVariationIndex === 0 || fetching) return;

    // if error is already occured in a prior fetch, re-execute the query without using cache
    // and set the error flag to default value
    if (isErrorAlreadyOccured.current && !fetching) {
      executeQuery({ requestPolicy: 'network-only' });
      isErrorAlreadyOccured.current = false;
      return;
    }

    // if error is not already occured in a prior fetch, set the error flag to true,
    // reset active variation index to 0 and show the error notification
    isErrorAlreadyOccured.current = true;
    setActiveVariationIndex(0);
    if (!isColorSelectFailedNotificationOpen) setIsColorSelectFailedNotificationOpen(true);
  }, [
    error,
    activeVariationIndex,
    fetching,
    isColorSelectFailedNotificationOpen,
    setIsColorSelectFailedNotificationOpen,
    executeQuery,
  ]);

  return (
    <ProductCard
      {...passthroughProps}
      {...activeVariationProps}
      clickTrackingProps={clickTrackingProps}
      wishListTrackingProps={{
        ...wishListTrackingProps,
        product: wishListTrackingProduct,
      }}
      shrinkToFit
      onColorChange={setActiveVariationIndex}
      onProductLinkActionAreaClick={handleClick}
    />
  );
};

export type SearchProductCardProps = ActualSearchProductCardProps & {
  alwaysRender?: boolean;
  onFirstBecameVisible?: () => void;
};

/**
 * Specialized version of `ProductCard` for the search product grids
 */
export const SearchProductCard: FC<SearchProductCardProps> = (props) => {
  const { alwaysRender, onFirstBecameVisible, ...actualSearchProductCardProps } = props;

  const { maskedItem, maskedResult } = actualSearchProductCardProps;

  const item = unmask<typeof SearchProductCardFragmentFragmentDoc>(maskedItem);
  const result = unmask<typeof SearchProductCardResultFragmentFragmentDoc>(maskedResult);

  const { dispatchProductImpressionTracking } = useProductImpressionTracking(result, item);

  const wrapper = useRef<HTMLDivElement>(null);
  const showSeenProducts = useAtomValue(showSeenProductsAtom);
  const [shouldRender, setShouldRender] = useState(alwaysRender);

  // check if the card is fully visible
  useIntersectionObserver(wrapper, {
    intersectOnce: true,
    threshold: 1,
    callback: (isIntersecting) => {
      if (!isIntersecting) return;

      // intersectOnce is set to true, so this callback will only be called once
      onFirstBecameVisible?.();
      dispatchProductImpressionTracking();

      if (showSeenProducts) {
        wrapper.current?.classList.add('product-card seen');
      }
    },
  });

  // check if the card will scroll into view soon (as defined by the margin)
  useIntersectionObserver(wrapper, {
    intersectOnce: true,
    threshold: 0,
    rootMargin: '500px 0px 500px 0px',
    callback: (isIntersecting) => {
      setShouldRender(isIntersecting);
    },
  });

  const isBot = useIsBot();

  const showActualCard = alwaysRender || shouldRender || isBot;

  const { styleId, styleName } = item;

  const { displayMode } = useDisplayMode(result);

  return (
    <Box
      ref={wrapper}
      sx={{ height: showActualCard ? '100%' : getHeightHeuristic(props, displayMode) }}
      id={styleId}
    >
      {showActualCard ? <ActualSearchProductCard {...actualSearchProductCardProps} /> : styleName}
    </Box>
  );
};
