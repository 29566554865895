import { unmask } from '@packages/gql/src/betterMasking';
import { type FragmentType } from '@packages/gql/generated/shopping';
import type { BackChannelRequestCodeBlocksFragmentFragmentDoc } from '@packages/gql/generated/shopping/BackChannelRequestCodeBlocksFragmentFragmentDoc';

import { CodeBlock } from '../codeBlocks/CodeBlock';
import { CopyHeader } from '../codeBlocks/CopyHeader';

/* GraphQL */ `
  fragment BackChannelRequestCodeBlocksFragment on EpsQueryResult {
    diagnostics {
      epsRequest
      epsResponse
    }
  }
`;

export const BackChannelRequestCodeBlocks = ({
  maskedResult,
}: {
  maskedResult: FragmentType<typeof BackChannelRequestCodeBlocksFragmentFragmentDoc>;
}) => {
  const {
    diagnostics: { epsRequest, epsResponse },
  } = unmask<typeof BackChannelRequestCodeBlocksFragmentFragmentDoc>(maskedResult);

  const request = JSON.stringify(JSON.parse(epsRequest), null, 2);
  const response = JSON.stringify(JSON.parse(epsResponse), null, 2);

  return (
    <>
      <CopyHeader content={request}>EPS Request</CopyHeader>
      <CodeBlock content={request} />
      <CopyHeader content={response}>EPS Response</CopyHeader>
      <CodeBlock content={response} maxLines={20} />
    </>
  );
};
