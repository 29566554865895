import type { FC, ReactNode } from 'react';
import { useRef, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material';

import { Minus32, Plus32 } from '@packages/themes/icons';
import { Box, Button, Collapse, Grid } from '@packages/shared';
import { useDeviceType } from '@packages/shared/src/hooks/useDeviceType/useDeviceType';
import { type FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { FilterSettingsDesktopFragmentFragmentDoc } from '@packages/gql/generated/shopping/FilterSettingsDesktopFragmentFragmentDoc';

import type { SelectedFilterValue } from '../../types';
import { FilterSettingsDesktopButton } from '../FilterSettingsDesktopButton/FilterSettingsDesktopButton';
import { useFilterSettingsPopover } from './useFilterSettingsPopover';
import { DynamicFilterSettingsDesktopPopover } from './FilterSettingsDesktopPopover.dynamic';

/* GraphQL */ `
  fragment FilterSettingsDesktopFragment on SearchFilterableAndSortableResult {
    id
    availableFilters {
      id
      displayName
      ...FilterSettingsDesktopPopoverFragment
    }
  }
`;

const messages = defineMessages({
  collapseFiltersLabel: {
    defaultMessage: 'Weniger anzeigen',
    description: 'button label for the desktop filter settings collapse button',
    id: 'filterSettingsDesktop.collapseButtonLabel',
  },
  expandFiltersLabel: {
    defaultMessage: 'Mehr anzeigen ({filterCount})',
    description: 'button label for the desktop filter settings expand button',
    id: 'filterSettingsDesktop.expandButtonLabel',
  },
});

export type FilterSettingsDesktopProps = {
  maskedData: FragmentType<typeof FilterSettingsDesktopFragmentFragmentDoc>;
  /** Callback fired when a user submits the selection of one or more filter values */
  onSubmit?: (selectedValues: SelectedFilterValue[]) => void;
  /** The list of currently selected filter values */
  selectedFilterValues: SelectedFilterValue[];
};

const CollapseButton: FC<{
  children: ReactNode;
  iconComponent: ReactNode;
  onClick?: () => void;
}> = ({ children, iconComponent, onClick }) => (
  <Button
    sx={{
      display: 'flex',
      height: '1.25rem',
      px: 1.2,
    }}
    fullWidth
    onClick={onClick}
  >
    <Box
      sx={{
        width: '100%',
        marginRight: 'auto',
        overflow: 'hidden',
        textAlign: 'left',
      }}
    >
      {children}
    </Box>
    {iconComponent}
  </Button>
);

const useCollapseTransition = () => {
  const [state, setState] = useState<'collapsed' | 'collapsing' | 'expanded'>('collapsed');

  const showMore = () => {
    setState('expanded');
  };

  const showLess = () => {
    setState('collapsing');
  };

  // deferred state update is necessary to support a proper collapse animation, otherwise the items just vanish instantly
  const handleCollapseExited = () => {
    setState('collapsed');
  };

  return {
    collapseIn: state === 'expanded',
    showingAllFilters: state !== 'collapsed',
    showMore,
    showLess,
    handleCollapseExited,
  };
};

/**
 * FilterSettingsDesktop component description displayed in storybook
 * */
export const FilterSettingsDesktop: FC<FilterSettingsDesktopProps> = ({
  maskedData,
  onSubmit,
  selectedFilterValues: initiallySelectedFilterValues,
}) => {
  const data = unmask<typeof FilterSettingsDesktopFragmentFragmentDoc>(maskedData);

  const { collapseIn, showingAllFilters, showMore, showLess, handleCollapseExited } =
    useCollapseTransition();

  const { isTablet } = useDeviceType();
  const theme = useTheme();

  const tenthFilterButtonRef = useRef<HTMLButtonElement>(null);

  const focusTenthFilterButton = () => {
    // using timeout to ensure that the button is focused after the collapse animation has finished
    setTimeout(() => {
      if (tenthFilterButtonRef.current) {
        tenthFilterButtonRef.current.focus();
      }
    }, 0);
  };

  const handleShowMore = () => {
    showMore();
    focusTenthFilterButton();
  };

  const columns = isTablet ? 4 : 5;

  const isCollapsible = data.availableFilters.length > 2 * columns;

  const maxFiltersWithoutCollapse = 2 * columns;
  const collapsedFilterCount = maxFiltersWithoutCollapse - 1;
  const isSingleRow = data.availableFilters.length <= columns;

  const showAll = showingAllFilters || data.availableFilters.length <= maxFiltersWithoutCollapse;

  const shownFilters = showAll
    ? data.availableFilters
    : data.availableFilters.slice(0, collapsedFilterCount);
  const additionalFilterCount = data.availableFilters.length - collapsedFilterCount;

  const itemSpacing = 2;
  // base item height of 36px is chosen to match the height of the collapse button and the filter buttons (specified there as 1.25rem, which curiously doesn't work in this calc expression)
  // spacing is added for each row, except for the last one
  const collapsedSize = `calc(${isSingleRow ? 1 : 2} * 36px + ${
    isSingleRow ? 0 : 1
  } * ${theme.spacing(itemSpacing)})`;

  const {
    activeFilterIds,
    selectedFilterId,
    popoverAnchor,
    isPopoverOpen,
    selectedFilterValues,
    hasChanges,
    selectFilter,
    closePopover,
    selectFilterValues,
  } = useFilterSettingsPopover(initiallySelectedFilterValues);

  const selectedFilter = data.availableFilters.find((filter) => filter.id === selectedFilterId);

  return (
    <>
      <Collapse in={collapseIn} collapsedSize={collapsedSize} onExited={handleCollapseExited}>
        <Grid container columns={isTablet ? 4 : 5} spacing={itemSpacing}>
          {shownFilters.map((filter, index) => (
            <Grid item xs={1} key={filter.id}>
              <FilterSettingsDesktopButton
                isActive={activeFilterIds.includes(filter.id)}
                isPopoverOpen={selectedFilterId === filter.id && isPopoverOpen}
                onClick={(anchor) => {
                  selectFilter(filter.id, anchor);
                }}
                ref={index === 9 ? tenthFilterButtonRef : undefined}
              >
                {filter.displayName}
              </FilterSettingsDesktopButton>
            </Grid>
          ))}
          {isCollapsible && (
            <Grid item xs={1} key="button">
              {showingAllFilters ? (
                <CollapseButton iconComponent={<Minus32 />} onClick={showLess}>
                  <FormattedMessage
                    key="collapseFiltersLabel-text"
                    {...messages.collapseFiltersLabel}
                  />
                </CollapseButton>
              ) : (
                <CollapseButton iconComponent={<Plus32 />} onClick={handleShowMore}>
                  <FormattedMessage
                    key="expandFiltersLabel-text"
                    {...messages.expandFiltersLabel}
                    values={{ filterCount: additionalFilterCount }}
                  />
                </CollapseButton>
              )}
            </Grid>
          )}
        </Grid>
      </Collapse>
      <DynamicFilterSettingsDesktopPopover
        disableScrollLock
        requestCacheKey={data.id}
        maskedData={selectedFilter}
        popoverAnchor={popoverAnchor.current}
        isPopoverOpen={isPopoverOpen}
        selectedFilterValues={selectedFilterValues}
        hasChanges={hasChanges}
        closePopover={closePopover}
        onChange={selectFilterValues}
        onSubmit={onSubmit}
      />
    </>
  );
};
