import { useState } from 'react';
import type { FC, ReactNode } from 'react';

import { Box } from '../Box';

import { useDebugMode } from '../../hooks/useDebugMode/useDebugMode';
import { Button } from '../Button';
import { DialogPreset } from '../DialogPreset';
import { DialogFooter } from '../DialogFooter';

export type DebugPanelProps = {
  children?: ReactNode;
  addons?: ReactNode;
};

export const DebugPanel: FC<DebugPanelProps> = ({ children, addons }) => {
  const { debugModeActive, disableDebugMode } = useDebugMode();
  const [dialogOpen, setDialogOpen] = useState(false);

  const closeDialog = () => setDialogOpen(false);

  if (!debugModeActive) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        margin: 2,
        zIndex: (theme) => theme.zIndex.modal - 1,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Button onClick={() => setDialogOpen(true)}>Debug Info</Button>

      {addons}

      <DialogPreset open={dialogOpen} onBack={closeDialog} onClose={closeDialog} title="Debug Info">
        <Box
          sx={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {children}
        </Box>

        <DialogFooter>
          <Button
            onClick={() => {
              closeDialog();
              disableDebugMode();
            }}
          >
            Disable debug mode
          </Button>
        </DialogFooter>
      </DialogPreset>
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default DebugPanel;
