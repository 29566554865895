import type { FC } from 'react';
import { Fragment } from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

import { Box, Chip } from '@packages/shared';
import type { FragmentType } from '@packages/gql/generated/shopping';
import { unmask } from '@packages/gql/src/betterMasking';
import type { SelectedFilterValueListFragmentFragmentDoc } from '@packages/gql/generated/shopping/SelectedFilterValueListFragmentFragmentDoc';

import { SPECIAL_CATEGORY_FILTER_ID } from '../../hooks/useSpecialCategoryFilterValue';
import { useSearchFilters } from '../../hooks/useFilters';
import { SelectedFilterValueChip } from '../SelectedFilterValueChip/SelectedFilterValueChip';
import { SelectedFilterRangeChip } from '../SelectedFilterRangeChip/SelectedFilterRangeChip';

const deleteAllLabel = defineMessage({
  id: 'filters.selectedValues.deleteAll',
  defaultMessage: 'Alle Filter zurücksetzen',
});

/* GraphQL */ `
  fragment SelectedFilterValueListFragment on SearchFilterableAndSortableResult {
    ...UseSearchFiltersFragment
    activeFilters {
      filterId
      activeValues {
        id
        ...SelectedFilterValueChipFragment
      }
    }

    ... on SuccessfulSearchResult {
      selectedCategory {
        id
        displayName
      }
    }
  }
`;

export type SelectedFilterValueListProps = {
  maskedData: FragmentType<typeof SelectedFilterValueListFragmentFragmentDoc>;
  /** If `true`, the color of the filter value is displayed on the chip */
  showColors?: boolean;
};

/**
 * A list of currently selected filter values
 * */
export const SelectedFilterValueList: FC<SelectedFilterValueListProps> = ({
  maskedData,
  showColors,
}) => {
  const data = unmask<typeof SelectedFilterValueListFragmentFragmentDoc>(maskedData);

  const { selectedFilterValues, pushFilterChange } = useSearchFilters(data);

  const removeFilterValue = (filterId: string, valueId: string) => {
    const otherValues = selectedFilterValues.filter((value) => value.filterId !== filterId);
    const relevantValue = selectedFilterValues.find((value) => value.filterId === filterId);

    if (!relevantValue) return;

    const { selectedValues, selectedRange } = relevantValue;

    pushFilterChange(
      otherValues.concat({
        filterId,
        selectedRange,
        selectedValues: selectedValues.filter((id) => id !== valueId),
      }),
    );
  };

  const removeRangeValue = (filterId: string) => {
    const otherValues = selectedFilterValues.filter((value) => value.filterId !== filterId);
    const relevantValue = selectedFilterValues.find((value) => value.filterId === filterId);

    if (!relevantValue) return;

    const { selectedValues } = relevantValue;

    pushFilterChange(otherValues.concat({ filterId, selectedValues }));
  };

  const removeAll = () => {
    pushFilterChange([]);
  };

  const getValueById = (filterId: string, valueId: string) =>
    data.activeFilters
      .find((f) => f.filterId === filterId)
      ?.activeValues?.find((v) => v.id === valueId);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
        flexWrap: 'wrap',
        width: '100%',
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexWrap: 'wrap',
          width: '100%',
          gap: 1,
          marginRight: 'auto',
        }}
      >
        {'selectedCategory' in data && data.selectedCategory && (
          <Chip
            label={data.selectedCategory.displayName}
            onDelete={
              () => removeFilterValue(SPECIAL_CATEGORY_FILTER_ID, data.selectedCategory?.id ?? '') // TS can't correctly infer here that `selectedCategory` is defined
            }
            onClick={
              () => removeFilterValue(SPECIAL_CATEGORY_FILTER_ID, data.selectedCategory?.id ?? '') // TS can't correctly infer here that `selectedCategory` is defined
            }
            variant="outlined"
          />
        )}
        {selectedFilterValues.flatMap(({ filterId, selectedValues, selectedRange }) => (
          <Fragment key={filterId}>
            {selectedRange && (
              <SelectedFilterRangeChip
                value={selectedRange}
                onDelete={() => removeRangeValue(filterId)}
              />
            )}
            {selectedValues
              .map((id) => getValueById(filterId, id))
              .map((value) =>
                value ? (
                  <SelectedFilterValueChip
                    key={`${filterId}-${value.id}`}
                    onDelete={() => removeFilterValue(filterId, value.id)}
                    maskedData={value}
                    showColors={showColors}
                  />
                ) : null,
              )}
          </Fragment>
        ))}
      </Box>
      {/* Box wrapper provides left-alignment for the Chip on small device sizes (overrides the default stretch alignment of the parent); this is functionally equivalent to `align-self: flex-start` directly on the chip, but makes it consistent with the other chips above that also have a Box wrapper */}
      {selectedFilterValues.length > 0 && (
        <Box>
          <Chip
            label={<FormattedMessage key="deleteAllLabel" {...deleteAllLabel} />}
            onDelete={removeAll}
            onClick={removeAll}
          />
        </Box>
      )}
    </Box>
  );
};
