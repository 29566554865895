import type { ReactNode } from 'react';
import { Button, Stack, Typography } from '@packages/shared';
import { Copy32 } from '@packages/themes/icons';
import { copyResponseToClipboard } from './copyResponseToClipboard';

export const CopyHeader = ({
  children,
  content,
}: {
  children: ReactNode;
  content: string | object;
}) => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Typography variant="h4">{children}</Typography>
    <Button variant="text" onClick={() => copyResponseToClipboard(content)}>
      <Copy32 />
      Copy to clipboard
    </Button>
  </Stack>
);
